import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    // Prevent form submission when a card is scanned.
    // When a card is read via an RFID reader attached to the PC,
    // a series of `keydown` events are triggered - one for each
    // symbol of the read card code and the final one, which is
    // for key `Enter` triggering a form submission.
    this.element.addEventListener("keydown", function(e) {
      if (e.keyCode == 13) e.preventDefault()
    } )
  }
}
