import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
  ]

  change(e) {
    console.log("Change tagholder", e.currentTarget.dataset.selectPath)

    const tagholderSelectPath = e.currentTarget.dataset.selectPath
    fetch(tagholderSelectPath)
      .then(response => response.text())
      .then((content) => {
        this.element.innerHTML = content
      })
      .catch(error => {
        alert("Ooops. Something went wrong.")
      })
  }
}
