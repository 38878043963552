import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["selectedWorkspace", "workspaceDetails"]

  connect() {
    if (this.hasSelectedWorkspaceTarget) {
      this.showWorkspaceDetails(this.selectedWorkspaceTarget)
      this.selectedWorkspaceTarget.scrollIntoView({ block: "center", inline: "center" })
    }
  }

  selectWorkspace(e) {
    const workspace = e.currentTarget
    this.showWorkspaceDetails(workspace)
  }

  showWorkspaceDetails(workspace) {
    if (this.hasWorkspaceDetailsTarget) {
      history.replaceState(history.state, null, workspace.dataset.url)

      if (this.selectedWorkspace) {
        this.selectedWorkspace.classList.replace(
          "bg-primary",
          this.selectedWorkspace.dataset.bgclass,
        )
      }

      this.selectedWorkspace = workspace;
      workspace.classList.replace(workspace.dataset.bgclass, "bg-primary")

      this.workspaceDetailsTarget.classList.remove("d-none")

      fetch(workspace.dataset.detailsUrl)
        .then(response => response.text())
        .then(content => {
          this.workspaceDetailsTarget.innerHTML = content
        })
        .catch(error => {
          alert("Oops. Something went wrong")
        })
    }
  }
}
