import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "startOn",
    "endOn",
    "floor",
    "workspaceInput",
    "workspaceSelect",
    "reloadForm",
  ]

  changeInput() {
    this.floorTarget.disabled = !this.startOnTarget.value
    this.renderFloorplan()
  }

  renderFloorplan() {
    const workspaceSelectController =
      this.application.getControllerForElementAndIdentifier(
        this.workspaceSelectTarget,
        "workspace-select"
      )

    workspaceSelectController.renderFloorplan({
      startOn: this.startOnTarget.value,
      endOn: this.endOnTarget.value,
      floorId: this.floorTarget.value,
      selectedWorkspaceId: this.workspaceInputTarget.value,
    })
  }

  selectWorkspace(e) {
    this.workspaceInputTarget.value = e.detail.id
    this.reloadForm()
  }

  reloadForm() { 
    this.reloadFormTarget.click()
  }
}
