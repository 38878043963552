import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "floorplan",
    "workspace",
    "selectedWorkspace",
  ]

  connect() {
    this.renderFloorplan(this.element.dataset)
  }

  renderFloorplan(props) {
    const {
      startOn,
      endOn,
      floorId,
      seats,
      extraSeats,
      type,
      selectedWorkspaceId,
    } = props

    if (startOn && floorId) {
      let url = new URL(
        this.element.dataset["floorplanUrl"].replace(":id", floorId)
      )
      const params = {
        from: startOn,
        to: endOn,
        seats: seats,
        extra_seats: extraSeats,
        type: type,
        workspace_id: selectedWorkspaceId,
      }

      Object.entries(params).forEach(([key, value]) => {
        if (value != undefined) {
          url.searchParams.append(key, value)
        }
      })

      fetch(url)
        .then(response => {
          return response.text()
        })
        .then(content => {
          this.floorplanTarget.innerHTML = content
        })
        .catch(error => {
          alert("Ooops. Something went wrong.")
        })

    } else {
      this.floorplanTarget.innerHTML = ""
    }
  }

  selectWorkspace(e) {
    const workspace = e.currentTarget
    if (workspace.dataset.subscribable !== "true") { return }

    // deselect previous workspace
    const prevWorkspace = this.selectedWorkspace || (this.hasSelectedWorkspaceTarget && this.selectedWorkspaceTarget)
    prevWorkspace && prevWorkspace.classList.replace("bg-primary", prevWorkspace.dataset.bgclass)

    workspace.classList.replace(workspace.dataset.bgclass, "bg-primary")
    this.selectedWorkspace = workspace

    const { id, url, detailsUrl, pricingPlan } = workspace.dataset
    const workspaceData = { id, url, detailsUrl, pricingPlan: JSON.parse(pricingPlan) }

    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("change", false, false, workspaceData)
    this.element.dispatchEvent(event)
  }
}
