import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.startRefreshing()
  }

  disconnect() {
    this.stopRefreshing()
  }

  fetchContent() {
    fetch(this.data.get("url")).
      then(response => response.text()).
      then(html => {
        if (html.length > 0) {
          this.element.outerHTML = html
        }
      })
  }

  startRefreshing() {
    this.refreshInterval = setInterval(() => {
      this.fetchContent()
    }, this.data.get("refreshInterval"))
  }

  stopRefreshing() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval)
    }
  }
}
