import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const expandAll = this.element.dataset.expandAll == "true"

    // expand all access zones that have sub-zones selected
    this.element
      .querySelectorAll("details")
      .forEach(function(details) {
        details.open = expandAll || details.querySelector(":scope > ul input:checked")
      })
  }

  toggleZoneSelection(e) {
    const input = e.currentTarget
    const zoneElement = input.closest("li")
    input.checked ?
        this.selectParentZone(zoneElement)
      : this.deselectZone(zoneElement)
  }

  selectParentZone(zoneElement) {
    const parentZoneElement = zoneElement.parentElement.closest("li")
    if (!parentZoneElement) { return }

    // select parent zones to ensure checked access zone can be reached
    parentZoneElement.querySelector(":scope > details input").checked = true
    this.selectParentZone(parentZoneElement)
  }

  deselectZone(zoneElement) {
    // deselect all subzones, because they would be unreachable if this one is not reachable
    zoneElement.querySelectorAll("input").forEach(function(input) {
      input.checked = false
    })
  }
}
