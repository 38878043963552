import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["description", "descriptionLocal", "amount"];

  populateInputs(e) {
    const { selectedIndex } = e.currentTarget.options
    const {
      description = "",
      descriptionLocal = "",
      price = "",
    } = e.currentTarget.options.item(selectedIndex).dataset

    this.descriptionTarget.value = description
    this.descriptionLocalTarget.value = descriptionLocal
    this.amountTarget.value = price
  }
}
