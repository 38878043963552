// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("select2")

import Chartkick from "chartkick";
import Chart from "chart.js";
window.Chartkick = Chartkick;
Chartkick.addAdapter(Chart);

// import "bootstrap/dist/css/bootstrap"
import "bootstrap/dist/js/bootstrap"
import "select2/dist/css/select2"
import "flatpickr/dist/flatpickr.css"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context("../images", true)
const imagePath = (name) => images(name, true)

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import flatpickr from "flatpickr"

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))


// MOVE this code out from this file and import it instead
$(document).on('turbolinks:load', function() {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  flatpickr(
    "[data-toggle='datepicker']",
    {
      dateFormat: "Y-m-d",
      locale: {
        firstDayOfWeek: 1,
      }
    }
  );

  // toggle sidebar nav on small resolutions
  $("[data-toggle='aside']").click(() => {
    $(".js-aside,.js-aside-backdrop").toggleClass("show")
  });
})
