import { Controller } from "stimulus"
import { format } from "date-fns"
import _ from "lodash/fp"

export default class extends Controller {
  static targets = [
    "startDateInput",
    "endDateInput",
    "membership",
    "reloadForm",
  ]

  connect() {
    this.updateMemberships()
  }

  reloadForm() {
    this.reloadFormTarget.click()
  }
  updateMemberships() {
    if (!this.hasMembershipTarget) { return }

    const subStartDate = this.startDateInputTarget.value
    const subEndDate = this.endDateInputTarget.value

    this.membershipTargets.forEach(target => {
      const datesNode = target.querySelector('[data-target="dates"]')
      const startDateNode = target.querySelector('[data-target="start-date"]')
      const endDateNode = target.querySelector('[data-target="end-date"]')
      const deleteIconNode = target.querySelector('[data-target="deleteIcon"]')
      const updateIconNode = target.querySelector('[data-target="updateIcon"]')
      const { startDate, endDate }  = target.dataset
      const newStartDate = _.max(_.compact([startDate, subStartDate]))
      const newEndDate = _.min(_.compact([endDate, subEndDate]))

      if (newStartDate && newEndDate && newStartDate > newEndDate) {
        datesNode.style.textDecoration = "line-through"
        startDateNode.innerText = this.formatDate(newStartDate)
        startDateNode.classList.add("text-muted")
        endDateNode.innerText = this.formatDate(newEndDate)
        endDateNode.classList.add("text-muted")
        updateIconNode.classList.add("d-none")
        deleteIconNode.classList.remove("d-none")
      } else {
        datesNode.style.textDecoration = null

        if (newStartDate !== startDate) {
          startDateNode.innerText = this.formatDate(newStartDate)
          startDateNode.classList.add("text-muted")
        } else {
          startDateNode.innerText = this.formatDate(startDate)
          startDateNode.classList.remove("text-muted")
        }

        if (newEndDate !== endDate) {
          endDateNode.innerText = this.formatDate(newEndDate)
          endDateNode.classList.add("text-muted")
        } else {
          endDateNode.innerText = this.formatDate(endDate)
          endDateNode.classList.remove("text-muted")
        }

        if (startDate === newStartDate && endDate === newEndDate) {
          updateIconNode.classList.add("d-none")
          deleteIconNode.classList.add("d-none")
        } else {
          updateIconNode.classList.remove("d-none")
          deleteIconNode.classList.add("d-none")
        }
      }
    })
  }

  formatDate(dateString) {
    return dateString ? format(new Date(dateString), "dd MMM, yyyy") : ""
  }
}
