import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "floorId",
    "startOn",
    "workspaceId",
    "workspaceSelect",
    "accessInputs",
  ]

  changeInput(e) {
    this.floorIdTarget.disabled = !this.startOnTarget.value
    this.renderFloorplan()
  }

  renderFloorplan() {
    const workspaceSelectController =
      this.application.getControllerForElementAndIdentifier(
        this.workspaceSelectTarget,
        "workspace-select"
      )

    workspaceSelectController.renderFloorplan({
      floorId: this.floorIdTarget.value,
      startOn: this.startOnTarget.value,
      selectedWorkspaceId: this.workspaceIdTarget.value,
      ...this.subscriptionAttributes(),
    })
  }

  selectWorkspace(e) {
    const { pricingPlan: workspacePricing, id: workspaceId, ..._rest } = e.detail
    this.workspaceIdTarget.value = workspaceId

    this.loadAccessInputs(workspaceId)
    const subscriptionPricing = this.subscriptionAttributes(workspacePricing, this.subscriptionAttributes)
    this.notifyOfPriceMismatch(workspacePricing, this.subscriptionAttributes())
  }

  subscriptionAttributes() {
    return JSON.parse(this.data.get("subscriptionParams"))
  }

  notifyOfPriceMismatch(workspacePricing, subscriptionPricing) {
    if (
      workspacePricing.seatPrice != subscriptionPricing.seatPrice ||
      workspacePricing.extraSeatPrice != subscriptionPricing.extraSeatPrice
    ) {
      alert(`
        The seat prices of the selected workspace are different than those of the relocated subscription.
        Prices of subscription will NOT be updated when it is relocated to the new workspace!
      `)
    }
  }

  loadAccessInputs(workspaceId) {
    this.accessInputsTarget.innerHTML = ""

    const accessInputsUrl = `${this.data.get("accessibility-inputs-url")}?workspace_id=${workspaceId}`
    fetch(accessInputsUrl)
      .then(response => response.text() )
      .then(content => { this.accessInputsTarget.innerHTML = content })
      .catch(error => {
        alert("Ooops. Something went wrong.")
      })
  }
}
