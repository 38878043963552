import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $(this.element).select2({ width: '100%' });

    let element = this.element;
    $(this.element).on("change", function(e) {
      // Ensure only events `triggered` by jQuery are handled.
      // We don't want infinitely handle the event we're dispatching below
      if (!e.isTrigger) return;

      var event = new Event("change");
      element.dispatchEvent(event);
    });
  }
}
